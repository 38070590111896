@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {


html {
  //font-family: 'Avenir', serif;
}

html {
    scroll-behavior: smooth;
}


/* header elements */
.header-bar {
  background-color: #EDFAFF;
  display: flex;
  justify: between;
  align-items: center;
  padding-top: 0px;
}

.header-right-items {
  display:flex; 
  flex-direction: row;
  justify: right;
  color: #054075;
  padding-right: 10px;
}

.header-menu-button {
  display: None;
}

.header-options {
  font-size: 1em;
  //font-family: Avenir;
  color: #054D8E;
  padding-right: 60px;
  margin-top: 5px;
}

.header-options:hover {
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 5px;
}

.header-get-started-btn {
  background: #054D8E;
  border: 1px solid #ACA9A9;
  border-radius: 8px;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 50px; 
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.header-get-started-btn:hover {
  box-shadow: rgba(0, 0, 0, 0) 0px 10px 20px;
  transform: translate3d(0px, -2px, 0px);
}

.header-get-started {
  font-size: 20px;
  //font-family: Avenir;
  font-weight: 600;
  /* padding-top: 10px; */
}

#big-blog {
  text-align: center;
  font-size: 3em;
  color: #054075;
  margin-top: 40px;
  font-weight: 500; 
}

.flex-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.blog-summary-container {
  text-align: left;
  border: 1px solid #E7E9EC;
  background-color: white;
  border: 1px solid #054D8E;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px;
  transition: all .3s ease-in-out;
  border-radius: 10px;
  margin-bottom: 30px;
}

.blog-summary-container:hover {
  box-shadow: rgba(0, 0, 0, 0.22) 0px 19px 43px;
  transform: translate3d(0px, -1px, 0px);
}

.blog-summary-elements {
  display: flex;
  flex-direction: row;
}

.blog-summary-title {
  margin-top: 10px;
  margin-left: 15px;
  padding-right: 10px;
  font-size: 22px;
  color: #054075;
  font-weight: 500;
  line-height: 1.25em;
}

.blog-summary-body {
  margin-top: 10px;
  margin-left: 15px;
  font-size: 16px;
  padding-right: 10px;
  font-weight: normal;
  color: black;
}

.blog-summary-readtime {
  margin-left: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 14px;
  color: #054075;
  padding-bottom: 5px;
  font-weight: normal;
}

.blog-summary-image {
  border-top-left-radius: 10px;
}

#blog-container {
  width: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  align-items: center;
}

#blog-img {
  margin-top: 50px;
  aspect-ratio: 2 / 1;
}

#blog-inline-img {
  width: 80vh;
}

#blog-img-footnote {
  font-size: small;
  margin-top: 5px;
  margin-bottom: 10px;
  font-style: italic;
}

#blog-title {
  font-size: 2em;
  color: #054075;
  font-weight: bold;
  margin-top: 20px;
}

#blog-tldr {
  margin-top: 20px;
  background-color: rgba(205, 225, 232, 0.3);
  border-radius: 8px;
}

#blog-tldr-title {
  font-size: 1.5em;
  font-weight: bold;
  text-align: left;
  padding-left: 20px;
  padding-top: 15px;
}

#blog-tldr-content {
  padding-left: 20px;
  text-align: left;
  font-size: large;
  padding-right: 10px;
}

#blog-tldr-content2 {
  padding-left: 20px;
  text-align: left;
  font-size: large;
  padding-right: 10px;
  padding-bottom: 15px;
}

#blog-tldr-bullets {
  padding-left: 50px;
  text-align: left;
  font-size: large;
  margin-top: 5px;
  padding-bottom: 20px;
}

#blog-section-bullets {
  padding-left: 50px;
  text-align: left;
  font-size: large;
  margin-top: 5px;
  margin-bottom: 5px;
}

#blog-section-tile {
  font-size: 1.5em;
  font-weight: bold;
  text-align: left;
  margin-top: 25px;
}

#blog-section-subtile {
  font-size: 1.25em;
  font-weight: bold;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

#hyperlink {
  color: blue;
  text-decoration: underline;
}

#blog-body {
  font-size: large;
  text-align: left;
}

#blog-end-contact {
  font-style: italic;

}

/* Styles for small screens */
@media (max-width: 449px) {

  .header-img-logo {
    width:175px;
  }

  .header-menu-button {
    display: flex;
    font-size: 30px;
    margin-right: 20px;
  }

  .blog-summary-container {
    width: 90%;
    height: 440px;
    border: None;
    border: 1px solid rgba(5, 64, 117, 0.15);
    border-radius: 5px;
    box-shadow: 0px 0px 1px 1px #E2E2E2;
  }

  .blog-summary-container:hover {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;
    transform: translate3d(0px, 0px, 0px);
  }

  #blog-container {
    width: 90%;
  }

  .blog-summary-elements {
    display: flex;
    flex-direction: column;
  }

  .blog-summary-image {
    width: 100%;
    aspect-ratio: 1.75/1;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .blog-summary-container {
    position: relative;
  }

  .header-right-items {
    display: None;  
  }

  .menu {
    font-size: 18px;
    color: #054075;
    font-weight: medium;
    list-style-type: none;
    display: none; /* Hide the menu items by default on smaller screens */
    flex-direction: column; /* Stack menu items vertically */
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #EDFAFF;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 100%;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    z-index: 100;
  }

  .menu.active {
    display: flex; /* Display the menu items when the menu is active */
  }

  .menu-toggle {
    display: block;
    cursor: pointer;
  }

}

/* Styles for small screens */
@media (min-width: 450px) and (max-width: 640px) {

  .header-menu-button {
    display: flex;
    font-size: 30px;
    margin-right: 20px;
  }

  .menu.active {
    display: flex; /* Display the menu items when the menu is active */
  }

  .menu-toggle {
    display: block;
    cursor: pointer;
  }
  
  .header-img-logo {
    width:175px;
  }
  
  .blog-summary-container {
    width: 440px;
    height: 440px;
  }

  #blog-container {
    width: 90%;
  }

  .blog-summary-elements {
    display: flex;
    flex-direction: column;
  }

  .blog-summary-image {
    width: 438px;
    height: 210px;
    border-top-right-radius: 10px;
  }

  .blog-summary-container {
    position: relative;
  }

  .header-right-items {
    display: None;  
  }

  .menu {
    font-size: 18px;
    color: #054075;
    font-weight: medium;
    list-style-type: none;
    display: none; /* Hide the menu items by default on smaller screens */
    flex-direction: column; /* Stack menu items vertically */
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #EDFAFF;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 100%;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    z-index: 100;
  }

  .menu.active {
    display: flex; /* Display the menu items when the menu is active */
  }

  .menu-toggle {
    display: block;
    cursor: pointer;
  }

}

/* Styles for medium screens */
@media (min-width: 641px) and (max-width: 1024px) {


  .header-menu-button {
    display: flex;
    font-size: 30px;
    margin-right: 20px;
  }

  .menu.active {
    display: flex; /* Display the menu items when the menu is active */
  }

  .menu-toggle {
    display: block;
    cursor: pointer;
  }

  .header-right-items {
    display: None;  
  }

  .menu {
    font-size: 18px;
    color: #054075;
    font-weight: medium;
    list-style-type: none;
    display: none; /* Hide the menu items by default on smaller screens */
    flex-direction: column; /* Stack menu items vertically */
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #EDFAFF;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 100%;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    z-index: 100;
  }

  .menu.active {
    display: flex; /* Display the menu items when the menu is active */
  }

  .menu-toggle {
    display: block;
    cursor: pointer;
  }

  .header-img-logo {
    width:175px;
  }

  .blog-summary-container {
    width: 90%;
    height: 250px;
  }

    #blog-container {
    width: 90%;
  }

  .blog-summary-image {
    width: 250px;
    height: 248px;
    border-bottom-left-radius: 10px;
  }

  .blog-summary-text-elements {
    position: relative;
  }

}
  /* Styles for large screens */
@media (min-width: 1025px) {

  .header-img-logo {
    /* max-width:70px; */
    height:55px;
  }

  .blog-summary-container {
      width: 1000px;
      height: 200px;
  }

  .blog-summary-image {
    width: 300px;
    height: 198px;
    border-bottom-left-radius: 10px;
  }

  .blog-summary-text-elements {
    position: relative;
  }

}

/* .blog-summary-readmore {
  padding-right: 10px;
  font-size: 14px;
  color: #054075;
  position: absolute;
  bottom: 0;
  right: 0;
  padding-bottom: 12px;
  font-weight: 500;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 5px;
  text-decoration-color: #F6AE2D; 
} */


}
